<template>
  <div class="pageContol studentList">
    <div class="framePage">
      <div class="framePage-title">
        <span>
          <em>当前位置：</em>
          <a href="javascript:;">考试管理</a>
          <i>></i>
          <a href="javascript:;" class="cur-a">线上考试管理</a>
        </span>
      </div>
      <div class="framePage-body">
        <div class="operationControl flexdc" style="align-items: flex-start">
          <div class="searchbox">
            <div title="考试名称" class="searchboxItem ci-full">
              <span class="itemLabel">考试名称:</span>
              <el-input v-model="retrievalData.examinationName" type="text" size="small" placeholder="请输入考试名称"
                clearable />
            </div>
            <div title="培训工种" class="searchboxItem ci-full">
              <span class="itemLabel">培训工种:</span>
              <el-select v-model="retrievalData.occupationId" filterable remote clearable size="small"
                :remote-method="getOccupation" placeholder="请至少输入两个字搜索">
                <el-option v-for="item in occupation" :key="item.occupationId" :label="item.occupationName"
                  :value="item.occupationId">
                </el-option>
              </el-select>
            </div>
            <div title="考试科目" class="searchboxItem ci-full">
              <span class="itemLabel">考试科目:</span>
              <el-select v-model="retrievalData.subjectCode" filterable clearable size="small" placeholder="请选择考试科目">
                <el-option v-for="item in subjectCodeList" :key="item.value" :label="item.label" :value="item.value">
                </el-option>
              </el-select>
            </div>
            <el-button type="primary" class="bgc-bv" round @click="getData()">查询</el-button>
            <el-button type="primary" class="bgc-bv" round @click="goAddEdit()">新建</el-button>
            <el-button type="primary" class="bgc-bv" round @click="chooseFromOrganization">从考务处选择</el-button>
          </div>
        </div>
        <div class="framePage-scroll">
          <div class="ovy-a">
            <el-table ref="multipleTable" :data="tableData" :height="tableHeight" size="small" tooltip-effect="dark"
              :header-cell-style="tableHeader" stripe>
              <el-table-column label="序号" align="center" type="index" :index="indexMethod" />
              <el-table-column label="考试名称" align="left" show-overflow-tooltip prop="examinationName" minWidth="180" />
              <el-table-column label="考试类型" align="left" show-overflow-tooltip prop="examType" minWidth="120">
                <template slot-scope="scope">
                  {{ $setDictionary("EXAMINATION_EXAM_TYPE", scope.row.examType) }}
                </template>
              </el-table-column>
              <el-table-column label="培训工种" align="left" show-overflow-tooltip prop="occupationName" minWidth="180" />
              <el-table-column label="培训等级" align="left" show-overflow-tooltip prop="levelCode" minWidth="100">
                <template slot-scope="scope">
                  {{ $setDictionary("ET_TRAIN_LEVEL", scope.row.levelCode) }}
                </template>
              </el-table-column>
              <el-table-column label="考试科目" align="left" prop="subjectCode" show-overflow-tooltip min-width="150">
                <template slot-scope="scope">
                  {{ $setDictionary("EA_EXAM_SUBJECT_CODE", scope.row.subjectCode) }}
                </template>
              </el-table-column>
              <el-table-column label="考试时间" align="left" show-overflow-tooltip min-width="300px">
                <template slot-scope="scope">
                  {{ scope.row.startTime | moment }} 至 {{ scope.row.endTime | moment }}
                </template>
              </el-table-column>
              <el-table-column label="考试人数" align="left" show-overflow-tooltip prop="peopleNum" minWidth="100" />
              <el-table-column label="试卷名称" align="left" prop="examState" min-width="200">
                <template slot-scope="scope">
                  <el-tooltip class="item" effect="dark" :content="scope.row.plist.map(e => e.paperName).join('；')"
                    placement="top">
                    <p class="nowrap" v-html="scope.row.plist.map(e => e.paperName).join('；')"></p>
                  </el-tooltip>
                </template>
              </el-table-column>
              <el-table-column label="考试状态" align="left" prop="examState" show-overflow-tooltip min-width="100">
                <template slot-scope="scope">
                  {{ $setDictionary("EXAMINATION_EXAM_STATE", scope.row.examState) }}
                </template>
              </el-table-column>
              <el-table-column label="阅卷状态" align="left" prop="scoringState" show-overflow-tooltip min-width="100">
                <template slot-scope="scope">
                  {{ scope.row.examState === "30" ? $setDictionary("EXAMINATION_SCORING_STATE", scope.row.scoringState)
                :
                "" }}
                </template>
              </el-table-column>
              <el-table-column label="监考老师" align="left" show-overflow-tooltip prop="invigilationTeacher"
                minWidth="180">
                <template slot-scope="scope">
                  {{ scope.row.invigilationTeacher || "--" }}
                </template>
              </el-table-column>
              <el-table-column label="阅卷老师" align="left" show-overflow-tooltip prop="markTeacher" minWidth="180">
                <template slot-scope="scope">
                  {{ scope.row.markTeacher || "--" }}
                </template>
              </el-table-column>
              <el-table-column label="操作" align="center" width="200px" fixed="right">
                <template slot-scope="scope">
                  <el-button type="text" style="padding: 0px 5px" size="mini" @click="goAddEdit(scope.row)">{{
                scope.row.examState == '30' ? '查看' : '编辑' }}考试</el-button>
                  <el-button type="text" style="padding: 0px 5px" size="mini"
                    @click="goExaminationPaperList(scope.row)">试卷列表</el-button>
                  <el-button type="text" style="padding: 0px 5px" size="mini"
                    @click="goStudentList(scope.row)">查看学员</el-button>
                  <el-button type="text" style="padding: 0px 5px" size="mini"
                    @click="setShortMessageRemindData(scope.row, 'invigilator')">监考老师</el-button>
                  <el-button type="text" style="padding: 0px 5px" size="mini"
                    @click="setShortMessageRemindData(scope.row, 'examinationConfig')">考试配置</el-button>
                  <el-button type="text" style="padding: 0px 5px" size="mini"
                    @click="setShortMessageRemindData(scope.row, 'reviewPaperConfig')">阅卷配置</el-button>
                  <el-button type="text" style="padding: 0px 5px" size="mini" :disabled="scope.row.examState != '20'"
                    @click="researchTimesOpen(scope.row)">调整时间</el-button>
                  <el-button type="text" style="padding: 0px 5px" size="mini"
                    @click="deleteData(scope.row)">删除</el-button>
                </template>
              </el-table-column>
              <Empty slot="empty" />
            </el-table>
          </div>
        </div>
        <PageNum :apiData="apiData" @sizeChange="sizeChange" @getData="getData" />
      </div>
    </div>
    <!-- 调整考试时间 - 弹框 -->
    <el-dialog title="调整考试时间" :visible.sync="researchTimesData.dialogVisible" width="600px"
      :before-close="researchTimesDataClose">
      <el-form :model="researchTimesData" style="width: 100%" :rules="researchTimesDataRules" label-width="120px"
        ref="researchTimesData">
        <el-form-item label="考试名称：">
          {{ researchTimesData.examinationName }}
        </el-form-item>
        <el-form-item label="调整前时间：">
          {{ researchTimesData.startTime }} 至 {{ researchTimesData.endTime }}
        </el-form-item>
        <el-form-item label="调整类型：">
          <el-radio-group v-model="researchTimesData.timeType">
            <el-radio label="10">重新答题</el-radio>
            <el-radio label="20">继续答题</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="调整后时间：" prop="times">
          <el-date-picker size="small" v-model="researchTimesData.times" type="datetimerange" align="right"
            range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" format="yyyy-MM-dd HH:mm:ss"
            value-format="yyyy-MM-dd HH:mm:ss">
          </el-date-picker>
        </el-form-item>
        <el-form-item>
          <p style="line-height: 22px;margin-top: 20px;">注:考试后调整考试时间，将会把所有的考生强制退出，其答题结果进行临时保存，可以选择让考生重新答题或继续之前的试卷进行答题。
          </p>
          <p style="line-height: 22px;margin-top: 10px;">考试开始时间往后变更，则学员不能继续答题，只能重新作答!</p>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button class="bgc-bv" size="small" round @click="researchTimesDataClose">关 闭</el-button>
        <el-button class="bgc-bv" size="small" round @click="researchTimesDataOk">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import Empty from "@/components/Empty.vue";
import PageNum from "@/components/PageNum.vue";
import List from "@/mixins/List";
import { resetKeepAlive } from "@/utils/common";
export default {
  name: "examination_onlineTest_onlineTestList",
  components: {
    Empty,
    PageNum,
  },
  mixins: [List],
  data() {
    return {
      // 检索数据
      retrievalData: {
        examinationName: "", // 考试名称
        occupationId: "", // 培训工种
        subjectCode: "", // 考试科目
      },
      // 培训工种 - 下拉数据
      occupation: [],
      // 考试科目 - 下拉数据
      subjectCodeList: [],
      // 调整时间 - 弹框数据
      researchTimesData: {
        dialogVisible: false, // 弹框状态
        examinationId: "", // 考试id
        examinationName: "", // 考试名称
        startTime: "", // 调整前考试开始时间
        endTime: "", // 调整前考试结束时间
        timeType: "20", // 调整类型
        times: "", // 调整后时间
      },
      // 调整时间 - 弹框数据校验
      researchTimesDataRules: {
        times: [
          { required: true, trigger: "change", message: "请选择调整后时间" },
        ],
      },
    };
  },
  created() {
    this.dataDictionary();
  },
  watch: {},
  computed: {},
  mounted() { },
  methods: {
    // 获取 - 培训工种
    getOccupation(occupationName) {
      if (occupationName.trim().length >= 2) {
        this.$post("/biz/examination_affairs/activity/selectCtOccupationList", { occupationName }, 3000, true, 6)
          .then((ret) => {
            this.occupation = ret.data
          })
          .catch((err) => {
            return;
          });
      } else {
        this.occupation = [];
      }
    },
    // 获取 - 数据字典
    dataDictionary() {
      // 考试科目
      const subjectCodeList = this.$setDictionary(
        "EA_EXAM_SUBJECT_CODE",
        "list"
      );
      const list1 = [];
      for (const key in subjectCodeList) {
        list1.push({
          value: key,
          label: subjectCodeList[key],
        });
      }
      this.subjectCodeList = list1;
    },
    // 获取 - 列表数据
    getData(pageNum = 1) {
      const params = {
        pageNum: pageNum,
        pageSize: this.pageSize,
      };
      if (this.retrievalData.examinationName) {
        params.examinationName = this.retrievalData.examinationName;
      }
      if (this.retrievalData.occupationId) {
        params.occupationId = this.retrievalData.occupationId;
      }
      if (this.retrievalData.subjectCode) {
        params.subjectCode = this.retrievalData.subjectCode;
      }
      this.doFetch({
        url: "/biz/examination/pageList",
        params,
        pageNum,
      }, true, 6);
    },
    // 跳转 - 新增编辑
    goAddEdit(row) {
      this.$router.push({
        path: "/web/examination/onlineTest/onlineTestListAddEdit",
        query: {
          examinationId: row?.examinationId ?? '',
          examState: row?.examState ?? ''
        }
      });
    },
    // 从考务处选择
    chooseFromOrganization() {
      this.$router.push({
        path: "/web/examination/onlineTest/chooseFromOrganization"
      });
    },
    // 试卷列表
    goExaminationPaperList(row) {
      this.$router.push({
        path: "/web/examination/onlineTest/examinationPaperList",
        query: {
          examinationId: row.examinationId, // 考试id
          examinationName: row.examinationName, // 考试名称
          subjectCode: row.subjectCode // 考试科目
        }
      });
    },
    // 查看学员
    goStudentList(row) {
      this.$router.push({
        path: "/web/examination/onlineTest/studentList",
        query: {
          examinationId: row.examinationId, // 考试id
          activityId: row?.activityId ?? '', // 考务id
        }
      });
    },
    // 跳转二级页
    setShortMessageRemindData({ examinationId }, path) {
      this.$router.push({
        path: `/web/examination/onlineTest/${path}`,
        query: { examinationId }
      })
    },
    // 调整时间 - 打开弹框
    researchTimesOpen(row) {
      this.researchTimesData.examinationId = row.examinationId;
      this.researchTimesData.examinationName = row.examinationName;
      this.researchTimesData.startTime = row.startTime;
      this.researchTimesData.endTime = row.endTime;
      if (row.startTime && row.endTime) {
        this.researchTimesData.times = [row.startTime.replaceAll("/", "-"), row.endTime.replaceAll("/", "-")];
      }
      this.researchTimesData.dialogVisible = true;
    },
    // 调整时间 - 打开弹框 - 确定
    researchTimesDataOk() {
      this.$refs.researchTimesData.validate((valid) => {
        if (valid) {
          let params = {
            examinationId: this.researchTimesData.examinationId, // 考试id
            timeType: this.researchTimesData.timeType, // 调整类型
            startTime: this.researchTimesData.times[0], // 考试开始时间
            endTime: this.researchTimesData.times[1], // 考试结束时间
          };
          console.log(params)
          this.$post("/biz/examination/editTime", params, 3000, true, 6)
            .then((res) => {
              this.researchTimesDataClose();
              this.$message({
                type: "success",
                message: "操作成功",
              });
              this.getData();
            })
            .catch(() => {
              return;
            });
        }
      })
    },
    // 调整时间 - 关闭弹框
    researchTimesDataClose() {
      this.researchTimesData = this.$options.data().researchTimesData;
    },
    // 删除
    deleteData(row) {
      this.$post("/biz/examination/delete/getTips", { examinationId: row.examinationId }, 3000, true, 6)
        .then((res) => {
          this.$confirm(res?.data ?? '您确定要删除该考试吗?', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          }).then(() => {
            this.$post("/biz/examination/delete", { examinationId: row.examinationId }, 3000, true, 6)
              .then((res) => {
                this.$message({
                  type: 'success',
                  message: '删除成功!'
                });
                this.getData();
              })
              .catch((err) => {
                return;
              });
          }).catch(() => { });
        })
        .catch((err) => {
          return;
        });
    },
  },
  beforeRouteLeave: resetKeepAlive,
  beforeRouteEnter(to, from, next) {
    next(vm => {
      // 通过 `vm` 访问组件实例
      if (from.path == '/web/examination/onlineTest/chooseFromOrganization' || from.path == '/web/examination/onlineTest/onlineTestListAddEdit' ||
        from.path == '/web/examination/onlineTest/invigilator' || from.path == '/web/examination/onlineTest/reviewPaperConfig') {
        vm.getData(vm.pageNum)
      }
    })
  }
};
</script>
<style lang="less" scoped>
/deep/ .nowrap {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
}
</style>